html, body,
#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.bg-home {
  background-color: #cacae5;
}

.bg-myprimary {
  background-color: #f96332;
}

.bg-mysecondary {
  background-color: #32C8F9;  
}

.bg-myaccent {
  background-color: #fb9270;
}

.text-myprimary {
  color: #f96332;
}

.text-mysecondary {
  color: #32C8F9;  
}

.text-myaccent {
  color: #fb9270;;
}

.bg-pinkish {
  background-color: #F07F7E;
}

.text-pinkish {
  color: #F07F7E;
}

.bg-darkgray {
  background-color: #e9ecef;
}

.login-input {
  border-radius: 25px;
  padding: 1.4em 2em;
}

/********Potentially to change focus glow color, for now just remove the options 
*********completely from the login/register inputs as the default blue looked stupid
.form-control:focus {
  border-color: #FFFFFF!important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255,255,255, 0.8)!important;
}
****************************/

.login-bttn {
  border-radius: 25px;
  padding: .65em 2em;
}

.login-input::placeholder { /* Most modern browsers support this now. */
  color:    #f96332;
}

.nav-link.active {
  background-color: #f96332;
}

.mycontainer {
  display: flex;
  flex-direction: column;
}

.dashcard {
  padding-left: 1rem;
}

.status-header {
  text-align: center;
  margin-top: 1rem;
}

.header-row {
  display: none;
}

#start, #end {
  display: none;
}

.my-item {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.my-item a {
  display: block;
}

.statusrow-action {
  box-shadow: 0 1px 2px rgba(0,0,0,0.15);
  transition: box-shadow 0.3s ease-in-out;
}

.statusrow-action:hover {
  transform: scale(1.01);
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.statusrow-action:hover:before {
  background: #1d7af3;
  opacity: 1 !important;
  position: absolute;
  z-index: 1;
  width: 3px;
  height: 100%;
  content: '';
  left: 0;
  top: 0;
}

.mygraphs {
  display: flex;
  flex-wrap: wrap;
}

.mygraphs > div {
  margin-bottom: 5px;
}

/************* Pagination not needed as PaginatedStatus.js component unused currently ***************
.paginate {
  display: flex;
  list-style-type: none;
  justify-content: flex-end;
}

.paginate-link {
  display: block;
  position: relative;
  text-decoration: none;
  padding: 0.5rem 1.0rem;
  line-height: 1.25;
  margin-left: -1px;
  background-color: transparent;
}

.paginate-link:hover {
  z-index: 2;
  text-decoration: none;
  background-color: #c6cace;
  border-color: #c6cace;
  transition: background-color .7s;
}

.page-item.active .paginate-link {
  z-index: 3;
  color: #fff;
  background-color: red;
  border-color: red;
  transition: background-color 1.3s;
}

************* Pagination not needed as PaginatedStatus.js component unused currently ***************/

.survey-numbers {
  font-family: 'Grandstander', cursive;
  font-size: 20pt;
}

.narrow-font {
  font-family: 'Pragati Narrow', sans-serif;
}

.font-header {
  font-family: 'Nunito', sans-serif;
}

.advent-pro {
  font-family: 'Advent Pro', sans-serif;
  font-size: 1.2em;
}

.trash {
  float: right;
}

.trash:hover {
  cursor: pointer;
  outline: none;
  border-color: #DC3545;
  box-shadow: 0 0 10px #DC3545;
}

.trash-response {
  cursor: pointer;
  padding-top: 2px;
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  width: 500px;
  background-color: #FFFFFF;
  font-weight: 400;
  color: #fb9270;
  border-radius: 10px;
  padding: 20px;
}

.error-title {
  /*font-family: 'Nunito', sans-serif; */
  font-size: 8rem;
  font-weight: 800;
}

.error-emphasis {
  color: red;
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: -2px;
  margin-right: 4px;
}

.face-size {
  font-size: 6rem;
  margin: 0 10px;
}

.my-form {
  padding: 1rem;
}

/*------------------------------------
- COLOR my-secondary
------------------------------------*/
.bg-my-secondary {
    background-color: #fb9270 !important;
}

a.bg-my-secondary:hover, a.bg-my-secondary:focus,
button.bg-my-secondary:hover,
button.bg-my-secondary:focus {
    background-color: #ed7d51 !important;
}

.border-my-secondary {
    border-color: #fb9270 !important;
}

.btn-my-secondary {
    color: #FFFFFF;
    background-color: #fb9270;
    border-color: #fb9270;
}

.btn-my-secondary:hover {
    color: #FFFFFF;
    background-color: #fb9270;
  /*
    background-color: #6770c5;
    border-color: #ed7d51;
  */
}

.btn-my-secondary:focus, .btn-my-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(230, 104, 55, 0.5);
}

.btn-my-secondary.disabled, .btn-my-secondary:disabled {
    color: #FFFFFF;
    background-color: #fb9270;
    border-color: #fb9270;
}

.btn-my-secondary:not(:disabled):not(.disabled):active, .btn-my-secondary:not(:disabled):not(.disabled).active, .show > .btn-my-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #ed7d51;
    border-color: #e66837;
}

.btn-my-secondary:not(:disabled):not(.disabled):active:focus, .btn-my-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-my-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(230, 104, 55, 0.5);
}

.btn-outline-my-secondary {
    color: #ffffff;
    background-color: transparent;
    border-color: #ffffff;
}

.btn-outline-my-secondary:hover {
    color: #ffffff;
    background-color: #fb9270;
    border-color: #ffffff;
}

.btn-outline-my-secondary:focus, .btn-outline-my-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(230, 104, 55, 0.5);
}

.btn-outline-my-secondary.disabled, .btn-outline-my-secondary:disabled {
    color: #fb9270;
    background-color: transparent;
}

.btn-outline-my-secondary:not(:disabled):not(.disabled):active, .btn-outline-my-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-my-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #fb9270;
    border-color: #fb9270;
}

.btn-outline-my-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-my-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-my-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(230, 104, 55, 0.5);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #menuBttn {
    margin-bottom: 1rem;
  }

  .cardrow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .dashcard {
    width: 220px;
    height: 160px;
    padding: 0;
  }

  .status-header {
    display: none;
  }

  .header-row {
    display: flex;
  }

  #start, #end {
    display: inline;
  }

  .my-item {
    flex-direction: row;
  }
  
  .my-item p {
    display: inline-block;
    flex: 0 0 16.6666667%
    /* equivalent to
    flex-grow: 0; //do no grow
    flex-shrink: 0 // do not shrink
    flex-basis: 33.3% // specifies main size of a flex item, determines size of context box
    */
  }
  
  .my-item a:nth-of-type(1) {
    display: inline-block;
    flex: 0 0 33.3333333%;
    text-align: left;
  }
  
  .my-item a:nth-of-type(2) {
    display: inline-block;
    flex: 0 0 16.6666667%
  }
  
  .mygraphs > div:nth-of-type(1) {
    margin-right: 1rem;
  }
  .mygraphs > div:nth-of-type(2) {
    margin-left: 1rem;
  }

  .my-form {
    padding: 2rem;
  }

  .error-title {
    /*font-family: 'Nunito', sans-serif; */
    font-size: 20rem;
    font-weight: 800;
  }

  .face-size {
    font-size: 15rem;
    margin: 0 10px;
  }

  .success-msg {
    font-size: 6rem;
    font-weight: 700;
    line-height: 1.2;
  }
}
  
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .mycontainer {
    /* height: 100vh; */
    flex-grow: 1;
    flex-direction: row;
  }
  
  .main-content {
    max-width: 900px;
    padding: 1.5rem;
  }
  
  #menuBttn {
    display: none;
  }
  
  #sidebar {
    /* height: 100vh; */
    width: 15rem;
    display: flex;
  }
  
  .cardrow {
    justify-content: space-between;
  }
}
  
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .mycontainer {
    width: 100%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}